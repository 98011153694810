import React, { useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
} from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const COPPage = ({ data }) => {

  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`City of Palmer Website PalmerAK.org - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>City of Palmer Website PalmerAK.org</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="City websites are a massive undertaking. The variety of content, the number of contributors, and the accessibility requirements make websites
        of this nature major projects. PalmerAK.org consists of thousands of pages and documents, and several custom made tools that helps users find
        content quickly."
        img={data.coptower.childImageSharp.fluid}
        color={colors.brand.slate}
        overlayColor="#fff"
        overlayOpacity="0.9"
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.copcompare.childImageSharp.fluid} />
      {/** BLOCK 03 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#abb8c3"
        heading="Design"
        body="PalmerAK.org is designed around clarity. The visual design emphasizes readability with optimized column width, simple navigation between
        and in sections, and minimal decorative elements. This clean design has a secondary benefit, making the website lightweight and swift to
        load—an important factor in accessibility. Simple elements, like the editable site background and the interactive weather tool give the
        site locality."
      />
      {/** Block 04 */}
      <Columns
        blockLeft={
          <PlainText
            size="small"
            heading="Content Auditing"
            body="The old City of Palmer website suffered from deep disorganization of content and frustrating usability. Before even discussing visual
            design, the Alopex team conducted interviews with key city personnel to understand the role of their departments and how they interface
            with citizens. At the same time, Alopex collected and organized content for departments to edit with readability in mind. Working with
            the city staff, the content structure and navigation was developed."
            bgColor="#fcb900"
            color={colors.brand.lightGray}
          />
        }
        blockRight={
          <PlainImage img={data.copcloseup.childImageSharp.fluid} size="small" />
        }
      />
      {/** BLOCK 05 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#eeeeee"
        heading="Development"
        body="PalmerAK.org is a powerful website built on Drupal that integrates a number of custom built interactions. Alopex developers
        engineered several tools to allow city staff to upload various content and have the website uniformly format it, this includes
        a directory system, file manager, and calendar."
      />
      {/** BLOCK 06 */}
      <PlainImage img={data.copresponsive.childImageSharp.fluid} />
      {/** BLOCK 07 */}
      <PlainText
        color={colors.brand.lightGray}
        bgColor="#3fadec"
        heading="Results"
        className="lastBlock"
        body="Unlike commercial websites, our goal was not to increase time on site, page views, or conversions on PalmerAK.org. Since publishing
        the new website, page views have fallen by 53% but average time per page has risen by 13%. This indicates that users are finding their
        results with fewer clicks, and spending more time on those results. Testament to the practicality of the redesign, the Alopex Palmer
        site is 10x faster to load than the previous site, taking an average of 0.05 seconds to load pages."
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query copPage {
    site {
      siteMetadata {
        title
      }
    }
    coptower: file(relativePath: { eq: "casestudies/cop/city-of-palmer-background_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    copcompare: file(relativePath: { eq: "casestudies/cop/Alopex_Palmer-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    copcloseup: file(relativePath: { eq: "casestudies/cop/Alopex_Palmer-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    copresponsive: file(relativePath: { eq: "casestudies/cop/Alopex_Palmer-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default COPPage
